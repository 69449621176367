import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 238.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,238.000000) scale(0.100000,-0.100000)">


<path d="M1495 1900 c-43 -44 -55 -61 -44 -65 10 -4 34 13 66 45 27 27 55 50
62 50 7 0 34 -20 60 -45 30 -28 57 -45 72 -45 21 1 16 9 -41 60 -84 78 -98 78
-175 0z"/>
<path d="M1350 1540 l0 -281 41 3 c22 1 44 8 48 16 6 11 13 10 39 -5 28 -16
35 -17 74 -3 37 13 43 20 50 55 12 54 6 295 -7 295 -6 0 -10 -51 -11 -133 -1
-160 -11 -190 -68 -192 -45 -1 -47 11 -3 21 17 4 27 9 20 11 -8 3 -9 12 -2 33
15 41 6 333 -10 338 -9 3 -9 7 0 18 15 18 39 18 39 0 0 -7 9 -16 20 -19 30 -8
26 29 -5 49 -28 18 -102 14 -115 -6 -13 -20 -22 7 -16 45 l6 35 -50 0 -50 0 0
-280z m91 188 c9 -16 9 -23 0 -26 -6 -2 -11 -11 -11 -20 0 -15 -2 -15 -10 -2
-9 13 -10 13 -10 0 0 -9 6 -22 13 -29 10 -11 9 -13 -5 -8 -15 5 -16 3 -7 -21
6 -15 9 -85 8 -156 -2 -92 1 -131 9 -134 16 -5 15 -29 0 -35 -27 -9 -53 10
-54 39 0 16 4 32 10 36 8 6 8 8 0 8 -7 0 -12 27 -13 73 -1 54 2 68 10 57 8
-11 9 -6 4 20 -4 19 -7 52 -8 72 -1 23 -5 34 -12 30 -6 -4 -6 -1 1 9 6 8 10
42 10 76 l-1 61 27 -14 c15 -8 33 -24 39 -36z m67 -215 c2 -177 2 -182 -19
-188 -11 -4 -26 -2 -32 4 -13 13 -17 327 -4 354 5 11 17 17 30 15 22 -3 22 -7
25 -185z"/>
<path d="M1638 1759 c3 -49 1 -59 -12 -59 -21 0 -22 -46 -1 -54 12 -4 15 -31
15 -149 0 -205 7 -218 124 -232 l57 -7 -3 33 c-2 28 -7 34 -28 35 -14 1 -31 2
-37 3 -10 1 -13 40 -13 161 0 148 -7 187 -25 143 -8 -21 -7 -245 2 -282 3 -14
10 -32 16 -39 7 -9 0 -8 -24 2 -28 12 -34 20 -34 45 0 17 -2 31 -5 31 -3 0 -5
30 -5 68 2 103 -13 193 -33 208 -16 13 -15 14 6 20 18 5 23 12 19 30 -2 13 -1
21 4 19 4 -3 9 5 11 17 2 13 11 24 21 26 14 3 17 -4 17 -37 0 -22 5 -43 11
-46 5 -4 14 -18 19 -31 8 -19 17 -24 44 -24 32 0 35 2 33 28 -2 24 -7 27 -43
30 l-41 3 4 59 3 59 -52 0 -53 0 3 -60z"/>
<path d="M1762 1778 c2 -13 19 -33 41 -47 20 -13 37 -29 37 -35 0 -7 29 -41
65 -76 36 -35 65 -72 65 -80 0 -9 -38 -54 -85 -100 -62 -60 -82 -86 -73 -91 7
-5 15 -8 18 -6 3 1 42 41 88 89 58 61 82 94 82 112 0 18 -29 55 -112 140 -62
64 -116 116 -121 116 -5 0 -7 -10 -5 -22z"/>
<path d="M1250 1657 c-75 -79 -89 -102 -82 -131 3 -12 38 -55 78 -97 58 -60
74 -72 74 -56 0 13 -25 47 -59 82 -33 33 -61 67 -63 77 -2 9 23 44 60 82 47
48 63 71 60 88 -3 20 -10 15 -68 -45z"/>
<path d="M1655 1209 c-64 -66 -68 -66 -124 -14 -30 28 -57 45 -72 45 -21 0
-17 -7 38 -60 39 -39 69 -60 85 -60 25 0 153 112 141 123 -13 14 -30 6 -68
-34z"/>
<path d="M1666 1022 c-3 -3 -6 -72 -6 -154 l0 -148 25 0 26 0 -3 152 c-3 133
-5 151 -20 154 -9 1 -19 -1 -22 -4z"/>
<path d="M450 1005 c0 -3 -1 -67 -1 -142 l0 -138 63 -3 c71 -2 105 11 127 49
18 32 7 79 -19 86 -25 7 -25 15 0 31 23 14 27 59 7 86 -7 9 -30 21 -52 26 -43
10 -125 13 -125 5z m134 -51 c33 -33 11 -63 -54 -71 l-40 -6 0 47 0 46 39 0
c23 0 46 -7 55 -16z m14 -137 c5 -41 -14 -57 -64 -57 l-44 0 0 43 c0 24 2 46
5 48 2 3 26 3 52 -1 44 -4 48 -8 51 -33z"/>
<path d="M1202 988 c3 -20 8 -22 46 -20 l42 4 0 -126 0 -126 26 0 25 0 -2 123
-1 122 46 3 c38 3 46 7 46 23 0 18 -8 19 -116 19 -114 0 -115 0 -112 -22z"/>
<path d="M751 933 c-34 -14 -55 -48 -59 -98 -4 -44 -1 -55 23 -83 22 -27 34
-32 70 -32 26 0 51 7 65 18 21 17 21 19 4 32 -13 12 -18 12 -21 3 -7 -20 -68
-16 -86 5 -31 35 -21 41 53 35 l70 -6 0 36 c0 60 -68 112 -119 90z m73 -55
c15 -24 5 -30 -48 -26 -46 3 -47 3 -30 26 19 27 61 27 78 0z"/>
<path d="M939 911 c-25 -25 -29 -37 -29 -84 0 -45 4 -59 26 -81 18 -18 37 -26
60 -26 40 0 84 19 84 36 0 19 -20 28 -33 15 -19 -19 -75 -13 -87 8 -18 34 -12
38 61 34 l72 -3 -6 42 c-11 82 -92 115 -148 59z m102 -38 c8 -22 6 -23 -41
-23 -55 0 -56 1 -37 31 17 27 67 22 78 -8z"/>
<path d="M1483 924 c-35 -17 -43 -37 -43 -106 0 -72 48 -108 124 -94 44 8 62
29 41 46 -12 10 -19 10 -27 2 -17 -17 -64 -15 -83 4 -28 28 -12 38 58 35 65
-2 67 -2 67 23 0 42 -18 74 -49 91 -37 19 -48 19 -88 -1z m86 -46 c8 -21 5
-23 -38 -29 -58 -8 -65 -4 -45 27 22 32 71 34 83 2z"/>
<path d="M1813 930 c-35 -14 -56 -56 -54 -108 3 -87 74 -127 153 -86 27 14 29
18 16 30 -13 13 -18 13 -43 0 -29 -15 -60 -8 -77 18 -19 28 -7 33 63 28 l72
-5 -6 44 c-9 71 -62 104 -124 79z m75 -55 c12 -25 11 -25 -38 -25 -52 0 -60 8
-34 34 24 24 59 20 72 -9z"/>
<path d="M2030 933 c-36 -15 -60 -62 -60 -119 0 -80 101 -124 155 -69 14 13
25 29 25 35 0 16 -38 12 -48 -6 -10 -19 -58 -22 -74 -6 -15 15 -13 99 2 117
19 22 61 19 67 -5 6 -22 53 -29 53 -7 -1 43 -72 78 -120 60z"/>
<path d="M2233 928 c-24 -12 -53 -64 -53 -97 0 -34 26 -88 48 -100 33 -18 90
-13 111 8 11 11 24 36 30 55 25 94 -55 173 -136 134z m81 -50 c9 -12 16 -39
16 -60 0 -29 -6 -41 -25 -54 -23 -15 -27 -15 -50 0 -45 30 -30 136 20 136 13
0 31 -10 39 -22z"/>
<path d="M2420 829 c0 -108 1 -110 22 -107 20 3 21 8 19 76 -3 80 7 102 45
102 33 0 39 -16 36 -102 l-4 -78 26 0 c26 0 26 1 26 79 0 85 10 105 52 99 22
-3 23 -8 26 -90 3 -86 3 -88 27 -88 25 0 25 1 25 84 0 69 -4 88 -21 110 -24
30 -63 34 -97 10 -20 -14 -24 -14 -44 0 -28 20 -52 20 -71 1 -13 -13 -16 -13
-27 0 -7 8 -19 15 -26 15 -11 0 -14 -23 -14 -111z"/>
<path d="M410 629 c0 -5 7 -9 15 -9 11 0 15 -12 15 -44 0 -25 5 -48 10 -51 6
-4 10 13 10 44 0 39 4 51 15 51 8 0 15 4 15 9 0 6 -18 9 -40 9 -22 0 -40 -4
-40 -9z"/>
<path d="M550 580 l0 -60 35 0 c19 0 35 5 35 10 0 6 -11 10 -25 10 -16 0 -25
6 -25 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 7 -20 15
0 9 10 15 26 15 14 0 23 4 19 10 -3 6 -19 10 -36 10 -29 0 -29 0 -29 -60z"/>
<path d="M680 582 c0 -62 0 -62 29 -62 17 0 33 5 36 10 4 6 -6 10 -22 10 -27
0 -28 2 -25 41 3 22 -1 46 -7 52 -8 8 -11 -5 -11 -51z"/>
<path d="M800 580 l0 -60 35 0 c19 0 35 5 35 10 0 6 -11 10 -25 10 -16 0 -25
6 -25 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 7 -20 15
0 9 10 15 26 15 14 0 23 4 19 10 -3 6 -19 10 -36 10 -29 0 -29 0 -29 -60z"/>
<path d="M942 624 c-26 -18 -30 -65 -6 -88 20 -20 60 -21 67 -1 5 11 0 13 -23
9 -25 -6 -30 -3 -36 20 -8 32 12 59 37 51 23 -8 26 10 3 19 -20 8 -16 9 -42
-10z"/>
<path d="M1066 618 c-22 -30 -20 -61 4 -83 36 -32 79 -4 80 53 0 17 -37 52
-54 52 -8 0 -22 -10 -30 -22z m54 -13 c15 -18 5 -65 -13 -65 -32 0 -48 45 -25
68 16 16 23 15 38 -3z"/>
<path d="M1210 580 c0 -40 4 -59 11 -55 6 4 7 19 4 36 -9 39 2 37 19 -3 12
-30 14 -31 21 -13 19 54 25 61 23 25 -1 -19 2 -37 7 -40 5 -3 9 19 8 50 -1 60
-17 78 -28 31 -9 -43 -23 -47 -33 -10 -15 58 -32 47 -32 -21z"/>
<path d="M1370 579 c0 -35 4 -58 9 -54 5 3 8 22 5 43 l-4 37 19 -40 19 -40 11
28 c13 34 25 35 19 2 -3 -14 -1 -28 3 -31 5 -3 9 22 9 55 0 67 -15 82 -26 26
-3 -19 -10 -35 -14 -35 -5 0 -14 16 -20 35 -17 53 -30 42 -30 -26z"/>
<path d="M1530 591 c0 -56 17 -76 55 -67 23 6 25 11 25 63 0 62 -14 65 -15 3
-1 -45 -8 -55 -30 -47 -10 5 -15 20 -15 52 0 25 -4 45 -10 45 -5 0 -10 -22
-10 -49z"/>
<path d="M1680 596 c0 -24 -3 -52 -7 -62 -4 -12 -2 -15 8 -12 9 4 13 17 11 42
-4 45 12 41 33 -10 21 -52 35 -42 35 26 0 33 -4 60 -10 60 -5 0 -10 -19 -11
-42 l0 -43 -22 43 c-11 23 -25 42 -29 42 -4 0 -8 -20 -8 -44z"/>
<path d="M1828 634 c-11 -11 -10 -102 1 -109 5 -3 11 -2 13 2 7 15 -7 115 -14
107z"/>
<path d="M1925 626 c-22 -16 -25 -67 -5 -91 15 -18 61 -17 68 2 2 9 -5 11 -26
7 -24 -5 -30 -2 -35 17 -10 36 9 60 41 52 24 -6 26 -5 13 10 -16 21 -31 21
-56 3z"/>
<path d="M2065 629 c-14 -41 -35 -102 -35 -105 0 -13 20 0 25 16 9 27 41 26
48 -2 4 -12 9 -19 13 -15 3 3 0 27 -7 52 -15 51 -36 76 -44 54z"/>
<path d="M2160 630 c0 -5 7 -10 15 -10 11 0 15 -12 15 -50 0 -27 5 -50 10 -50
6 0 10 23 10 50 0 44 3 50 21 50 11 0 17 5 14 10 -3 6 -24 10 -46 10 -21 0
-39 -4 -39 -10z"/>
<path d="M2300 580 c0 -33 4 -60 10 -60 6 0 10 27 10 60 0 33 -4 60 -10 60 -6
0 -10 -27 -10 -60z"/>
<path d="M2398 629 c-20 -11 -24 -59 -8 -90 13 -23 52 -25 70 -4 18 22 15 77
-6 92 -21 15 -33 16 -56 2z m47 -49 c0 -28 -4 -35 -20 -35 -13 0 -21 8 -23 23
-5 34 5 54 25 50 13 -2 18 -13 18 -38z"/>
<path d="M2539 638 c-7 -14 -5 -109 2 -113 5 -4 9 13 9 37 l0 43 26 -43 c14
-23 29 -40 33 -38 11 7 12 105 0 111 -5 4 -9 -12 -9 -37 l0 -43 -26 43 c-22
38 -31 47 -35 40z"/>
<path d="M2693 633 c-22 -8 -14 -43 12 -55 35 -16 34 -42 -1 -34 -19 5 -25 3
-22 -6 3 -7 18 -13 34 -13 24 0 30 5 32 26 2 19 -3 28 -23 37 -33 15 -33 36 0
28 14 -4 25 -2 25 4 0 5 -4 10 -9 10 -5 0 -15 2 -23 4 -7 3 -19 2 -25 -1z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
